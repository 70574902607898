<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1300px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="项目订购批次记录"
      @ok="handleSubmit"
    >
      <div class="custom-red">在此可减少客户采购项目的数量，如若新增请联系客户在小程序或厅房触摸屏上进行操作。</div>
      <div class="custom-flex custom-justify-between set-background-box">
        <div>
          <div style="font-weight: bolder">已选项目</div>
          <div style="margin-top: 10px">共{{ itemCount }}项</div>
        </div>
        <div class="custom-flex" style="background-color: #ff9e1c">
          <a-icon type="pay-circle" style="height: 35px;width: 35px;color: #ffffff;margin: 3px;fontSize:45px" />
          <div style="color:#ffffff;padding-left: 10px;">
            项目金额：
            <div class="set-price-font">{{ totalFee | formatCurrency }}元</div>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="editingRecords"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :scroll="{ y: 350 }"
      >
        <template slot="images" slot-scope="imgs, record">
          <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
        </template>
        <div slot="count" slot-scope="count, record" class="custom-flex count-edit-box">
          <a-input-number v-if="record.give === false" :value="record.count" :min="0" @change="(value) => changeCount(value, record)" />
          <a-input-number v-else :value="record.count" :min="0" disabled />
          <span style="float: right;padding-top: 5px;">个</span>
        </div>
      </a-table>
      <a-form
        class="custom-flex-col custom-flex"
        :form="itemForm"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        style="padding-top: 30px"
      >
        <a-row>
          <a-col :span="8">
            <a-form-item label="货品配货负责人" v-if="hasAssortingTask === true">
              <a-select
                v-decorator="['flower_room_user_id', {
                  initialValue: defaultAssortUser.id > 0 ? defaultAssortUser.id : undefined,
                  rules: [
                    { required: true, message: '请选择货品配货负责人' },
                  ]
                }]"
                placeholder="请选择货品配货负责人"
                @load="loadingAssortUserOptions"
                @change="handleAssortingPhoneNumberChange"
              >
                <a-select-option
                  v-for="user in assortUserOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="花房派工负责人" v-if="hasDispatchingTask === true">
              <a-select
                v-decorator="['front_flower_user_id', {
                  initialValue: defaultDispatchUser.id > 0 ? defaultDispatchUser.id : undefined,
                  rules: [
                    { required: true, message: '请选择花房派工负责人' },
                  ]
                }]"
                placeholder="请选择花房派工负责人"
                @load="loadingDispatchUserOptions"
                @change="handleDispatchingPhoneNumberChange"
              >
                <a-select-option
                  v-for="user in dispatchUserOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="厅房布置负责人">
              <a-select
                v-decorator="['hall_clean_user_id', {
                  initialValue: defaultLayoutUser.id > 0 ? defaultLayoutUser.id : undefined,
                  rules: [
                    { required: true, message: '请选择厅房布置负责人' },
                  ]
                }]"
                placeholder="请选择厅房布置负责人"
                @load="loadingLayoutUserOptions"
                @change="handleHallPhoneNumberChange"
              >
                <a-select-option
                  v-for="user in layoutUserOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="备注"
            >
              <a-textarea
                v-decorator="['remark', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 1000, message: '请输入备注，最多1000个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系电话" v-if="hasAssortingTask === true">
              {{ distributionUserPhone }}
            </a-form-item>
            <a-form-item label="联系电话" v-if="hasDispatchingTask === true">
              {{ flowerUserPhone }}
            </a-form-item>
            <a-form-item label="联系电话">
              {{ hallUserPhone }}
            </a-form-item>
          </a-col>

        </a-row>

      </a-form>
      <!-- 预览图片 -->
      <preview-image
        :visible.sync="previewVisible"
        :images.sync="previewImages"
      />

    </a-modal>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/filter'
import PreviewImage from '@/components/PreviewImage'
import { message } from 'ant-design-vue'
import { confirmOtherItem, findConfirmSummary } from '@/api/order'
import { findTenantTaskUserOptions } from '@/api/user'

export default {
  name: 'ItemOrderBatchRecord',
  components: {
    PreviewImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      itemForm: this.$form.createForm(this, { name: 'determine_other_items' }),
      submitting: false,
      loading: false,
      previewVisible: false,
      previewImages: [],
      editingRecords: [],
      tempRecord: [],
      query: {},
      itemCount: 0,
      totalFee: 0,
      defaultTotalFee: 0,
      defaultItemCount: 0,
      assortUserOptions: [],
      defaultAssortUser: {},
      dispatchUserOptions: [],
      defaultDispatchUser: {},
      layoutUserOptions: [],
      defaultLayoutUser: {},
      loadingAssortUserOptions: false,
      loadingDispatchUserOptions: false,
      loadingLayoutUserOptions: false,
      distributionUserPhone: '',
      flowerUserPhone: '',
      hallUserPhone: ''
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    hasAssortingTask() {
      let hasAssorting = false
      this.editingRecords.forEach((res) => {
        if (res.task_type_slugs != null && res.task_type_slugs.includes('assorting') && res.count > 0) {
          hasAssorting = true
        }
      })
      return hasAssorting
    },
    hasDispatchingTask() {
      let hasDispatching = false
      this.editingRecords.forEach((res) => {
        if (res.task_type_slugs != null && res.task_type_slugs.includes('dispatching') && res.count > 0) {
          hasDispatching = true
        }
      })
      return hasDispatching
    },
    hasHallLayoutTask() {
      let hasHallLayout = false
      this.editingRecords.forEach((res) => {
        if (res.task_type_slugs != null && res.task_type_slugs.includes('hall_layout') && res.count > 0) {
          hasHallLayout = true
        }
      })
      return hasHallLayout
    },
    columns() {
      return [
        {
          title: '品名',
          width: 100,
          dataIndex: 'product_name'
        },
        {
          title: '规格',
          width: 90,
          dataIndex: 'product_specification_name'
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '价格',
          width: 90,
          dataIndex: 'price_display'
        },
        {
          title: '数量',
          width: 200,
          dataIndex: 'count',
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '订单号',
          width: 90,
          dataIndex: 'project_no'
        },
        {
          title: '金额(元)',
          width: 100,
          dataIndex: 'fee',
          customRender: formatCurrency
        },
        {
          title: '订购时间',
          width: 120,
          dataIndex: 'created_at'
        }
      ]
    }
  },
  created() {
    this.fetchData()
    this.fetchUserOptions()
  },
  methods: {
    changeCount(val, record) {
      this.tempRecord.forEach((item, index) => {
        if (item.id === record.id) {
          if (val > item.count) {
            message.error('数量不能大于原数量，如若新增请联系客户在小程序或厅房触摸屏上进行操作。')
          } else {
            record.count = val
            record.fee = record.price * record.count
            this.totalFee = this.defaultTotalFee - item.fee + record.fee
          }
        }
      })
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },
    fetchData() {
      findConfirmSummary(this.id, this.query).then((res) => {
        this.editingRecords = res.data.data
        this.itemCount = res.data.item_count
        this.totalFee = res.data.total_fee
        this.defaultTotalFee = this.totalFee
        this.defaultItemCount = this.itemCount
        this.tempRecord = JSON.parse(JSON.stringify(this.editingRecords))
      })
    },
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    plus(record) {
      this.tempRecord.forEach((item, index) => {
        if (item.id === record.id) {
          if ((record.count + 1) > item.count) {
            message.error('数量不能大于原数量，如若新增请联系客户在小程序或厅房触摸屏上进行操作。')
          } else {
            record.count++
            record.fee = record.price * record.count
            this.totalFee = this.defaultTotalFee - item.fee + record.fee
          }
        }
      })
    },

    minus(record) {
      if (record.count > 0) {
        record.count--
        record.fee = record.price * record.count
        this.tempRecord.forEach((item, index) => {
          if (item.id === record.id) {
            this.totalFee = this.defaultTotalFee - item.fee + record.fee
          }
        })
      }
    },
    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.itemForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const editData = Object.assign({}, values, { items: this.editingRecords })
          confirmOtherItem(this.serviceOrderId, editData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },
    fetchUserOptions() {
      this.loadingAssortUserOptions = true
      this.loadingDispatchUserOptions = true
      this.loadingLayoutUserOptions = true
      findTenantTaskUserOptions({ task_type: 'assorting', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.assortUserOptions = res.data.option_list
          this.defaultAssortUser = res.data.default_user
          this.distributionUserPhone = this.defaultAssortUser.phone_number
        }
        this.loadingAssortUserOptions = false
      })

      findTenantTaskUserOptions({ task_type: 'dispatching', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.dispatchUserOptions = res.data.option_list
          this.defaultDispatchUser = res.data.default_user
          this.flowerUserPhone = this.defaultDispatchUser.phone_number
        }
        this.loadingDispatchUserOptions = false
      })

      findTenantTaskUserOptions({ task_type: 'hall_layout', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.layoutUserOptions = res.data.option_list
          this.defaultLayoutUser = res.data.default_user
          this.hallUserPhone = this.defaultLayoutUser.phone_number
        }
        this.loadingLayoutUserOptions = false
      })
    },
    handleDispatchingPhoneNumberChange(e) {
      this.dispatchUserOptions.forEach((res) => {
        if (res.id === e) {
          this.flowerUserPhone = res.phone_number
        }
      })
    },
    handleAssortingPhoneNumberChange(e) {
      this.assortUserOptions.forEach((res) => {
        if (res.id === e) {
          this.distributionUserPhone = res.phone_number
        }
      })
    },
    handleHallPhoneNumberChange(e) {
      this.layoutUserOptions.forEach((res) => {
        if (res.id === e) {
          this.hallUserPhone = res.phone_number
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-edit-box {
  user-select: none;
}
</style>
